import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./BenefitsOfWorking.module.scss"

const BenefitsOfWorking = ({ strapiData }) => {
  console.log(strapiData);
  
  return (
    <div className={styles.dedicatedTeam}>
      <Container>
        <h1
          className={`${styles.heading} `}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
          <Row className={`${styles.rowCards} gap-20`}>
            {strapiData?.cards?.map((v, i) => (
              <Col lg={4} key={i}>
                <div className={`${styles.cards}`}>
                  <div>
                    <img
                      src={v?.image1[0]?.localFile?.publicURL}
                      style={{
                        height: "80px",
                        width: "80px",
                      }}
                      alt='icon'
                    />
                    <h3 dangerouslySetInnerHTML={{
                      __html: v?.title,
                    }} />
                    <p style={{color:'#8C8C8C'}}>{v?.subTitle}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
      </Container>
    </div>
  )
}

export default BenefitsOfWorking;
