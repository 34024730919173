// extracted by mini-css-extract-plugin
export var SliderWidth = "WhyChooseInvo-module--SliderWidth--5d5b5";
export var Trust = "WhyChooseInvo-module--Trust--9a998";
export var card = "WhyChooseInvo-module--card--1bfb8";
export var cardDec = "WhyChooseInvo-module--cardDec--e75d6";
export var content = "WhyChooseInvo-module--content--c66dd";
export var description = "WhyChooseInvo-module--description--7a417";
export var devOpsDec = "WhyChooseInvo-module--devOpsDec--37050";
export var devOpsHead = "WhyChooseInvo-module--devOpsHead--d78e8";
export var devOpsTrust = "WhyChooseInvo-module--devOpsTrust--dcb38";
export var heading = "WhyChooseInvo-module--heading--2bedf";
export var iconContainer = "WhyChooseInvo-module--iconContainer--29e0e";
export var iconContainerLeft = "WhyChooseInvo-module--iconContainerLeft--1f456";
export var portfolioArrowIcon = "WhyChooseInvo-module--portfolioArrowIcon--e6281";
export var portfolioArrowIconCover = "WhyChooseInvo-module--portfolioArrowIconCover--b6c43";
export var portfolioArrowRightIconCover = "WhyChooseInvo-module--portfolioArrowRightIconCover--a15cf";
export var sqaArrowLeft = "WhyChooseInvo-module--sqaArrowLeft--4426e";
export var sqaArrowRight = "WhyChooseInvo-module--sqaArrowRight--4a119";
export var sqaPageHead = "WhyChooseInvo-module--sqaPageHead--09fbf";
export var topText = "WhyChooseInvo-module--topText--f5e37";
export var webArrowLeft = "WhyChooseInvo-module--webArrowLeft--dca6e";
export var webArrowRight = "WhyChooseInvo-module--webArrowRight--01f22";
export var webScopeBg = "WhyChooseInvo-module--webScopeBg--f78d5";