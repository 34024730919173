// extracted by mini-css-extract-plugin
export var card = "AIConsultingServices-module--card--c9be4";
export var cardCounting = "AIConsultingServices-module--cardCounting--d357a";
export var cardHeading = "AIConsultingServices-module--cardHeading--62bcb";
export var cardImage = "AIConsultingServices-module--cardImage--c7e03";
export var cardsContainer = "AIConsultingServices-module--cardsContainer--1e537";
export var cta = "AIConsultingServices-module--cta--e1f87";
export var description = "AIConsultingServices-module--description--57cef";
export var heading = "AIConsultingServices-module--heading--f444d";
export var highlight = "AIConsultingServices-module--highlight--cabd1";
export var imgContainer = "AIConsultingServices-module--imgContainer--cd78b";
export var sectionContainer = "AIConsultingServices-module--sectionContainer--0b058";