// extracted by mini-css-extract-plugin
export var card = "Approch-module--card--971c1";
export var cardWrapper = "Approch-module--cardWrapper--64770";
export var concerns = "Approch-module--concerns--5f22e";
export var customScale = "Approch-module--customScale--de8a1";
export var description = "Approch-module--description--5c29c";
export var desp = "Approch-module--desp--6eafb";
export var enterpriseBuildBg = "Approch-module--enterpriseBuildBg--6c6e8";
export var fixed = "Approch-module--fixed--d0cb3";
export var headInline = "Approch-module--headInline--5456a";
export var heading = "Approch-module--heading--08a62";
export var hire = "Approch-module--hire--ec1fd";
export var hireCyber = "Approch-module--hireCyber--fa32d";
export var imgCol = "Approch-module--imgCol--8b6ad";
export var leftCol = "Approch-module--leftCol--d6320";
export var number = "Approch-module--number--9d8d6";
export var start = "Approch-module--start--2dcbc";
export var subHeading = "Approch-module--subHeading--05ac5";
export var talkBtn = "Approch-module--talkBtn--ad69a";
export var webAppCard = "Approch-module--webAppCard--178f4";
export var webAppWalking = "Approch-module--webAppWalking--be918";