// extracted by mini-css-extract-plugin
export var SliderWidth = "BusinessSector-module--SliderWidth--03954";
export var arrowPoint = "BusinessSector-module--arrowPoint--27cf5";
export var blurInOut = "BusinessSector-module--blurInOut--f3eb6";
export var businessSector = "BusinessSector-module--businessSector--3bd99";
export var buton = "BusinessSector-module--buton--48323";
export var cardContent = "BusinessSector-module--cardContent--ba594";
export var cardFooter = "BusinessSector-module--cardFooter--6516a";
export var cardHover = "BusinessSector-module--cardHover--83471";
export var cards = "BusinessSector-module--cards--6f09d";
export var description = "BusinessSector-module--description--2a52a";
export var greyCard = "BusinessSector-module--greyCard--e00e9";
export var heading = "BusinessSector-module--heading--5ecdc";
export var headingContainer = "BusinessSector-module--headingContainer--6601a";
export var iconContainer = "BusinessSector-module--iconContainer--fd21f";
export var iconContainerLeft = "BusinessSector-module--iconContainerLeft--48a9a";
export var image2 = "BusinessSector-module--image2--a764f";
export var indDec = "BusinessSector-module--indDec--6afa5";
export var newBtn = "BusinessSector-module--newBtn--d0bea";
export var points = "BusinessSector-module--points--a63d2";
export var portfolioArrowIcon = "BusinessSector-module--portfolioArrowIcon--e8d65";
export var portfolioArrowIconCover = "BusinessSector-module--portfolioArrowIconCover--995ad";
export var portfolioArrowRightIconCover = "BusinessSector-module--portfolioArrowRightIconCover--621e8";
export var redCard = "BusinessSector-module--redCard--bcc53";
export var sheildImgTop = "BusinessSector-module--sheildImgTop--b4ef2";
export var shieldTopBannerImg = "BusinessSector-module--shieldTopBannerImg--f7c45";
export var skill = "BusinessSector-module--skill--9ffd8";
export var text = "BusinessSector-module--text--f4f8b";