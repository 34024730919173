// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--c9803";
export var bannerHeading = "Banner-module--bannerHeading--40c70";
export var bnrImg = "Banner-module--bnrImg--796ce";
export var btnsOuter = "Banner-module--btnsOuter--15309";
export var computerVisionBanner = "Banner-module--computerVisionBanner--1afe3";
export var cta = "Banner-module--cta--ab010";
export var curve = "Banner-module--curve--df7b6";
export var customeHead = "Banner-module--customeHead--8ad59";
export var description = "Banner-module--description--1c10b";
export var heading = "Banner-module--heading--0b1b5";
export var list = "Banner-module--list--a8e2c";
export var listItem = "Banner-module--listItem--b3a11";
export var mainTitle = "Banner-module--mainTitle--4c10b";
export var spanColor = "Banner-module--spanColor--a3483";
export var transparentBtn = "Banner-module--transparentBtn--02870";